<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      'expanded menu-dark'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
      >
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <li class="nav-item mr-auto mx-auto">
            <b-link
              class="navbar-brand"
              :to="loggedUsersHomeRoute"
            >
              <span class="brand-logo">
                <b-img
                  :src="appLogo"
                  alt="logo"
                />
              </span>
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <transition name="fade">
        <vertical-nav-menu-items
          v-if="isAdminPortal"
          :items="adminNavs"
          class="navigation navigation-main"
        />
        <vertical-nav-menu-items
          v-if="isChampionPortal"
          :items="responsiveChampionNavs"
          class="navigation navigation-main"
        />
        <vertical-nav-menu-items
          v-if="isParticipantPortal && userHasParticipantRole && !inEnrolementStep"
          :items="responsiveParticipantNavs"
          class="navigation navigation-main"
        />
      </transition>
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import adminNavs from '@/navigation/vertical/admin-navs';
import championNavs from '@/navigation/vertical/champion-navs';
import participantNavs from '@/navigation/vertical/participant-navs';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { BLink, BImg } from 'bootstrap-vue';
import { provide, computed, ref } from '@vue/composition-api';
import useAppConfig from '@core/app-config/useAppConfig';
import { $themeConfig } from '@themeConfig';
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue';
import useVerticalNavMenu from './useVerticalNavMenu';
import {
  PARTICIPANT_NAVS_MENTOR_MENU_ID,
  PARTICIPANT_NAVS_MENTEE_MENU_ID
} from '@/constants/participant-navs';
import { programTypes, userRoles } from "@/models";
import trainingNavs from '@/navigation/vertical/training-navs';
import traineeNavs from '@/navigation/vertical/trainee-navs';

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapGetters("app", [
      "isAdminPortal",
      "isChampionPortal",
      "isParticipantPortal"
    ]),
    ...mapGetters('programs',['defaultProgram']),
    ...mapGetters('profile', ['profile']),
    ...mapGetters("participants", [
      "matches",
      "trainings",
      "surveys",
      "resources",
    ]),
    inEnrolementStep () {
      return this.$route.query.enrolement;
    },
    loggedUsersHomeRoute () {
      if(this.isAdminPortal) {
        return {name: 'admin-home'};
      } 
      if(this.isChampionPortal) {
        return {name: 'champion-home'};
      } 
      if(this.isParticipantPortal) {
        return {name: 'participant-home'};
      }
      return {name: 'admin-home'};
    },
    participantCurrentProgram () {
      return this.$store.getters['participants/program'];
    },
    appLogo() {
      if (this.isParticipantPortal && this.participantCurrentProgram && this.participantCurrentProgram.logo) {
        return this.participantCurrentProgram.logo;
      }
      if(this.isChampionPortal) {
        if(this.defaultProgram?.primary_program_logo?.url)
        {
          return this.defaultProgram.primary_program_logo.url;
        }
        return this.$store.getters['appConfig/programLogo'];
      }
      if (this.skin === 'semi-dark' || this.skin === 'dark') {
        return this.appLogoHImageW;
      } else {
        return this.appLogoHImageB;
      }
    },
    mentorDisplay() {
      return this.$store.getters['participants/mentorDisplay'];
    },
    menteeDisplay() {
      return this.$store.getters['participants/menteeDisplay'];
    },
    responsiveChampionNavs() {
      let nav = !this.isProgramTypeTraining ? this.championNavs : this.trainingNavs;
      if (this.defaultProgram?.training_disabled) {
        nav.forEach(el => {
          if (el.children) {
            el.children = el.children.filter(item => {
              return item.route.name != 'champion-program-training-list';
            });
          }
        });
      }
      const clientSubDomain = this.defaultProgram && this.defaultProgram.client ? this.defaultProgram.client.path : '';
      const programPath = this.defaultProgram ? this.defaultProgram.path : '';
      const knowledgeBaseItem = {
        title: 'Knowledge base',
        href: `${process.env.VUE_APP_KNOWLEDGEBASE_URL}/champion-support?oauth_url=${window.location.origin}&client_sub_domain=${clientSubDomain}&program_path=${programPath}`
      };
      const supportMenu = {
        title: 'Support',
        icon: 'BookIcon',
        children: [
          {
            title: 'Library',
            route: 'champion-program-supports-resources'
          },
          ...(!this.isProgramTypeTraining ? [knowledgeBaseItem] : []),
          {
            title: 'Contact us',
            href: this.contactUsLink
          },
        ]
      };

      return [
        ...nav,
        supportMenu
      ];
    },
    hasMatchesNav() {
      if(this.matches?.total > 0) {
        return  {
          title: 'My Matches',
          icon: 'UsersIcon',
          route: 'participant-my-matches',
          resource: 'ParticipantNavs',
          action: 'read',
        };
      }
      return {};
    },
    hasTrainingNav() {
      if(this.trainings?.total > 0 && !this.defaultProgram.training_disabled) {
        return {
          title: 'Training',
          icon: 'BookIcon',
          route: 'participant-training-list',
          resource: 'ParticipantNavs',
          action: 'read',
        };
      }
      return {};
    },
    hasSurveys() {
      if(this.surveys?.total > 0) {
        return {
          title: 'Surveys',
          icon: 'Edit3Icon',
          route: 'participant-surveys',
          resource: 'ParticipantNavs',
          action: 'read',
        };
      }
      return {};
    },
    contactUsLink() {
      var supportEmail = this.defaultProgram?.aom_support_email;
      let subject = `${encodeURIComponent(this.defaultProgram?.name)}: Champion Support Request`;
      let cc = '';
      if (this.defaultProgram?.program_admins?.length > 0 && !this.isProgramTypeTraining) {
        let programAdmins = this.defaultProgram?.program_admins;
        supportEmail = encodeURIComponent(programAdmins[0].email);
        programAdmins.shift();
        cc = programAdmins.map(programAdmin => {
          return encodeURIComponent(programAdmin.email);
        }).join(',');
      }
      return `mailto:${supportEmail}?cc=${cc}&subject=${subject}`;
    },
    isProgramTypeTraining() {
      return this.defaultProgram?.type_id === programTypes.TRAINING;
    },
    hasResources() {
      if(this.resources?.total > 0) {
        return {
          title: 'Resources',
          icon: 'BookOpenIcon',
          route: 'participant-resources',
          resource: 'ParticipantNavs',
          action: 'read',
        };
      }
      return {};
    },
    responsiveParticipantNavs() {
      const navs = this.isProgramTypeTraining ? this.traineeNavs : this.participantNavs;
      let nav = [
        ...navs,
        this.hasMatchesNav,
        this.hasTrainingNav,
        this.hasResources,
        this.hasSurveys,
      ];

      const userData = this.profile;
      if (this.isParticipantPortal && userData?.logged_in_by && Number(userData?.logged_in_by.id) !== Number(userData.id)) {
        nav = nav.filter(nav => nav.route !== 'participant-messages');
      }
      return nav;
    },
    userHasParticipantRole () {
      return this.profile?.roles?.includes(userRoles.MENTEE) || this.profile?.roles?.includes(userRoles.MENTOR) || this.profile?.roles?.includes(userRoles.TRAINEE);
    }
  },
  setup(props) {
    const {
      isMouseHovered,
      updateMouseHovered,
    } = useVerticalNavMenu(props);

    const { skin } = useAppConfig();

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false);

    provide('isMouseHovered', isMouseHovered);

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };


    // App Name
    const {
      appName,
      appLogoImage,
      appLogoHImageW,
      appLogoHImageB
    } = $themeConfig.app;

    return {
      adminNavs,
      championNavs,
      participantNavs,
      perfectScrollbarSettings,

      isMouseHovered,
      updateMouseHovered,
  

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
      appLogoHImageW,
      appLogoHImageB,
      trainingNavs,
      traineeNavs
    };
  },
  mounted() {
    const mentorMenuItem = window.document.getElementById(PARTICIPANT_NAVS_MENTOR_MENU_ID);
    if (mentorMenuItem) {
      const spanElement = mentorMenuItem.querySelector('.menu-title');
      if (spanElement) {
        spanElement.innerHTML = `${this.mentorDisplay} Application`;
      }
    }

    const menteeMenuItem = window.document.getElementById(PARTICIPANT_NAVS_MENTEE_MENU_ID);
    if (menteeMenuItem) {
      const spanElement = menteeMenuItem.querySelector('.menu-title');
      if (spanElement) {
        spanElement.innerHTML = `${this.menteeDisplay} Application`;
      }
    }
   
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
